<template>
	<div class="page">

		<div class="topFundBlock">
			<van-row class="fundInfo">
				<van-col span="24"  class="tags">
					<!-- <p class="value">{{ fund.rate_today }}</p>
					<p class="name">日涨幅({{ fund.date }})</p> -->

					<van-tag color="#ffe1e1" text-color="#FB6B23"  v-for="(item, idx) in fund.tags" :key="idx">{{ item }}</van-tag>
							
				</van-col>
			</van-row>

			<!-- <van-row class="rateBlock">
				<van-col span="6">
					<p class="value">{{ fund.rate_last_3mon }}</p>
					<p class="name">最近三月</p>
				</van-col>
				<van-col span="6">
					<p class="value">{{ fund.rate_last_1year }}</p>
					<p class="name">最近一年</p>
				</van-col>
				<van-col span="6">
					<p class="value">{{ fund.rate_this_year }}</p>
					<p class="name">今年以来</p>
				</van-col>
				<van-col span="6">
					<p class="value">{{ fund.rate_so_far }}</p>
					<p class="name">成立以来</p>
				</van-col>
			</van-row> -->
		</div>

		<section class="fundBlock">
			<h4 class="title">{{translate('overview')}}</h4>
			<div class="content">
				<van-row class="table-item">
					<van-col span="24" class="itemTitle">{{translate('introduction')}}</van-col>
					<template>
						<div v-for="(item, key) in fund_info" :key="key">
							<van-col span="6" class="name">{{item.name}}</van-col>
							<van-col span="18" class="value">{{item.value}}</van-col>
						</div>
					</template>	
				</van-row>
			</div>
		</section>

		<section class="fundBlock">
			<h4 class="title">{{translate('rate_structure')}}</h4>
			<div class="content">
				<van-row class="table-item">
					<van-col span="24" class="itemTitle">{{translate('subscription_rate')}}</van-col>
					<van-col span="24" style="font-size: .8rem;padding-left: 5px;padding-right: 5px;padding-top: 5px;color: #C8C8C8;">
						<span>{{translate('subscription_amount')}}</span>
						<span style="float: right;">{{translate('subscription_rate')}}</span>
					</van-col>
					<template>
						<div v-for="(item, key) in buy_rates" :key="key">
							<van-col span="18" class="name">{{item.show_name}}</van-col>
							<van-col span="6" class="value" style="color: #FB6B23;">{{item.show_value}}</van-col>
						</div>
					</template>					
				</van-row>

				<van-row class="table-item">
					<van-col span="24" class="itemTitle">{{translate('management_fee')}}</van-col>
					<van-col class="value" style="color: #FB6B23;">{{ fund.fund_manage_rate }}%</van-col>				
				</van-row>

				<van-row class="table-item">
					<van-col span="24" class="itemTitle">{{translate('redemption_rate')}}</van-col>
					<van-col span="24" style="font-size: .8rem;padding-left: 5px;padding-right: 5px;padding-top: 5px;color: #C8C8C8;">
						<span>{{translate('application_share_holding')}}</span>
						<span style="float: right;">{{translate('redemption_rates')}}</span>
					</van-col>

					<template>
						<div v-for="(item, key) in redemption_rates" :key="key">
							<van-col span="18" class="name">{{item.show_name}}</van-col>
							<van-col span="6" class="value" style="color: #FB6B23;">{{item.show_value}}</van-col>
						</div>
					</template>					
				</van-row>

			</div>
		</section>

		<section class="fundBlock">
			<h4 class="title">{{translate('document')}}</h4>
			<div class="content">
				<van-row class="table-item">
					<van-col span="24" style="font-size: .8rem;padding-left: 5px;padding-right: 5px;padding-top: 5px;color: #C8C8C8;">
						<span>{{translate('type')}}</span>
						<span style="float: right;">{{translate('update_time')}}</span>
					</van-col>
					<template>
						<div v-for="(item, key) in files" :key="key">
							<van-col span="16" class="name" @click="openFundFile(item.name, item.file)"><van-icon :name="icon_file" style="transform: translateY(1px);margin-right: 3px;" />{{item.name}}</van-col>
							<van-col span="8" class="value">{{item.date}}</van-col>
						</div>
					</template>					
				</van-row>

			</div>
		</section>

		<section class="fundBlock">
			<h4 class="title">{{translate('fund_introduction')}}</h4>
			<div class="content">
				<div class="desc" v-html="fund.description"></div>
			</div>
		</section>

	</div>
</template>

<script>
	export default {
		name: 'fund_detail',
		data() {
			return {

				id: 0,

				icon_file: require("../../assets/images/icon_file.png"),

				tab_active: 0,
				fund: {
					name: "",
					code: "",
					rate_today: "",
					rate_last_3mon: "",
					rate_last_1year: "",
					rate_this_year: "",
					rate_so_far: "",
					net_value: "",
					fund_manage_rate: "",
					date: "",
					description: "",
					tags: [],
				},


				fund_info: [],
				fund_manager: [],
				fund_advantage: [],
				history_profit: [],
				history_nets: [],

				buy_rates: [],
				redemption_rates: [],
				files: [],

				achievements_x: [],
				achievements_v: [],
			}
		},
		mounted() {
			this.id = this.$route.query.id
		
			this.loadData()

			//window.openFundFile = this.openFundFile // 将方法挂载到windows上

		},
		activated() {
			//	如果第二次进入修改的数据不同，则重新获取数据
			if (this.$route.query.id != this.id) {
				this.id = this.$route.query.id
		
				this.loadData()
			}
		},
		methods: {

			openFundFile(name, file) {
				console.log(file)
				if (window.JsObject != null) {
                    window.JsObject.openFundFile(name, file);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"openFundFile": [name, file]});
                }
			},

			nets(){
				this.$router.push('/fund/nets?id=' + this.id) 
			},

			loadData() {
				this.$axios({
					method: 'post',
					url: 'fund_fund/detail', 
					data: {
						id: this.id
					}
				}).then ((res) => {
					if (res.success) {
						
						this.fund_info = res.data.fund_info
						this.buy_rates = res.data.buy_rates
						this.redemption_rates = res.data.redemption_rates
						this.fund_files = res.data.fund_files
						this.history_nets = res.data.history_nets
						this.history_achievements = res.data.history_achievements
						this.files = res.data.files

						this.fund = res.data.fund

					}
				})
			}
		},
	}
</script>

<style scoped>
	.page {
		padding: 1rem;
		padding-block-start: 5rem;
	}
	.topFundBlock {
		width: 100%;
		height: 4rem;
		background: linear-gradient(180deg, #FB6B23 0%, #FF8B51 0%, #FB6B23 0%, #FF945F 100%, #FF8447 100%);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}
	.fundInfo {
		padding: 1rem;
	}
	.fundInfo .value {
		font-size: 1.4rem;
		color: #FFFFFF;
	}
	.fundInfo .name {
		height: 1.5rem;
		font-size: .8rem;
		color: #FFE4C1;
	}

	.fundInfo .tags > span {
		display: inline-block;
		font-size: .5rem;
		color: #FB6B23;
		background-color: #FFCCB2;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 3px;
		border-radius: 5px;
	}
	.rateBlock {
		width: calc(100% - 2rem);
		height: 6rem;
		text-align: center;
		position: absolute;
		left: 1rem;
		bottom: -3rem;
		background-color: #FFFFFF;
		border-radius: 1rem;
		padding-top: 1rem;
		box-sizing: border-box;
	}
	.rateBlock .name {
		height: 2rem;
		line-height: 2rem;
		color: #6C6C6C;
	}
	.rateBlock .value {
		height: 2rem;
		line-height: 2rem;
		color: #110601;
	}
	.fundBlock {
		width: 100%;
		min-height: 8rem;
		overflow-y: scroll;
		background-color: #FFFFFF;
		border-radius: .5rem;
		box-sizing: border-box;
		padding: 1rem;
		margin-bottom: 1rem;
	}
	.fundBlock > .title {
		font-size: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid #E5E5E5;
	}

	.itemTitle {
		background-color: #F6F7F7;
		padding: 10px;
		color: #110601;
		font-size: .8rem;
		font-weight: bold;
	}
	.fundBlock .table-item .name {		
		padding: 10px 5px;
		color: #110601;
	}
	.fundBlock .table-item .value {
		padding: 10px 5px;
		color: #6C6C6C;
		text-align: right;
	}
</style>